/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

@import "./theme/variables.scss";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import './theme/colors.scss';
@import './theme/typography.scss';
@import './theme/utils.scss';
@import './theme/components/buttons';
@import './theme/components/status';
@import './theme/components/forms';
@import './theme/components/toast';
@import './theme/components/segment-heading';
@import './theme/components/segment-menu';
@import './theme/components/modal';
@import './theme/components/table';

@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

body {
  @include body-text-16;
}

p {
  line-height: $line-height--paragraph;
}

h1 {
  @include headline-h1;
}

h2 {
  @include headline-h2;
}

h3 {
  @include headline-h3;
}

ion-grid {
  --ion-grid-padding: 0;

  ion-col {
    --ion-grid-column-padding: 0;
  }
}

.semibold {
  font-weight: $font-weight--semibold;
}

.bold {
  font-weight: $font-weight--bold;
}

/*
style for fact find nav elements
created in directive
*/
div.anchor-link {
  display: flex;
  gap: 17px;
  margin-bottom: 10px;
  padding: 5px 10px;
  color: $color-dark-blue-400;
  border: 1px solid transparent;
  border-radius: $border-radius--x-large;
  transition: all ease-in .15s;
  cursor: pointer;

  &.active {
    ion-icon {
      margin-top: 1px;
      color: var(--ion-color-primary);
    }

    ion-label {
      @include headline-h3;
      color: var(--ion-color-secondary);
    }
  }

  &.current {
    border: 1px solid var(--ion-color-primary);
    background-color: $color-pure-white;
  }

  ion-icon {
    min-width: 18px;
    min-height: 18px;
    margin-top: 3px;
  }

  ion-label {
    align-self: center;
    @include body-text-14;
    line-height: 24px;
  }
}

.iconBtnPaddingEnd {
  margin-inline-end: 6px;
}

.popover300::part(content) {
  width: 300px !important;
}

ion-select::part(icon) {
  opacity: 0.5;
  width: 13px;
  position: absolute;
  right: var(--padding-end);
}

.m-t-0 {
  margin-top: 0;
}

.m-b-0 {
  margin-bottom: 0;
}