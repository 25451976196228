// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import './colors.scss';

/** Ionic CSS Variables **/
:root {
	--ion-color-primary: #{$color-light-blue-900};
	--ion-color-primary-rgb: #{$color-light-blue-900-rgb};
	--ion-color-primary-contrast: #{$color-pure-white};
	--ion-color-primary-contrast-rgb: #{$color-pure-white-rgb};
	--ion-color-primary-shade: #{$color-light-blue-950};
	--ion-color-primary-tint: #{$color-light-blue-500};

	--ion-color-secondary: #{$color-dark-blue-900};
	--ion-color-secondary-rgb: #{$color-dark-blue-900-rgb};
	--ion-color-secondary-contrast: #{$color-pure-white};
	--ion-color-secondary-contrast-rgb: #{$color-pure-white-rgb};
	--ion-color-secondary-shade: #{$color-midnight-900};
	--ion-color-secondary-tint: #{$color-dark-blue-700};

	--ion-color-tertiary: #{$color-red-500};
	--ion-color-tertiary-rgb: #{$color-red-500-rgb};
	--ion-color-tertiary-contrast: #{$color-midnight-900};
	--ion-color-tertiary-contrast-rgb: #{$color-midnight-900-rgb};
	--ion-color-tertiary-shade: #{$color-red-600};
	--ion-color-tertiary-tint: #{$color-red-300};

	--ion-color-success: #{$color-green-900};
	--ion-color-success-rgb: #{$color-green-900-rgb};
	--ion-color-success-contrast: #{$color-midnight-900};
	--ion-color-success-contrast-rgb: #{$color-midnight-900-rgb};
	--ion-color-success-shade: #{$color-green-950};
	--ion-color-success-tint: #{$color-green-300};

  --ion-color-warning: #{$color-yellow-950};
	--ion-color-warning-rgb: #{$color-yellow-950-rgb};
	--ion-color-warning-contrast: #{$color-midnight-900};
	--ion-color-warning-contrast-rgb: #{$color-midnight-900-rgb};
	--ion-color-warning-shade: #{$color-yellow-1000};
	--ion-color-warning-tint: #{$color-yellow-900};

	--ion-color-danger: #{$color-red-900};
	--ion-color-danger-rgb: #{$color-red-900-rgb};
	--ion-color-danger-contrast: #{$color-pure-white};
	--ion-color-danger-contrast-rgb: #{$color-pure-white-rgb};
	--ion-color-danger-shade: #{$color-red-950};
	--ion-color-danger-tint: #{$color-red-600};

	--ion-color-medium: #{$color-dark-blue-25};
	--ion-color-medium-rgb: #{$color-dark-blue-25-rgb};
	--ion-color-medium-contrast: #{$color-midnight-900};
	--ion-color-medium-contrast-rgb: #{$color-midnight-900-rgb};
	--ion-color-medium-shade: #{$color-dark-blue-300};
	--ion-color-medium-tint: #{$color-dark-blue-10};

	--ion-color-light: #{$color-dark-blue-5};
	--ion-color-light-rgb: #{$color-dark-blue-5-rgb};
	--ion-color-light-contrast: #{$color-midnight-900};
	--ion-color-light-contrast-rgb: #{$color-midnight-900-rgb};
	--ion-color-light-shade: #{$color-dark-blue-100};
	--ion-color-light-tint: #{$color-pure-white};

  --ion-background-color: #{$color-pure-white};
  --ion-background-color-rgb: #{$color-pure-white-rgb};
  --ion-background-color-dirty: #{$color-light-grey-900};
  --ion-text-color: #{$color-dark-blue-900};
  --ion-text-color-rgb: #{$color-dark-blue-900-rgb};
  --ion-placeholder-color: #{$color-dark-grey-500};
  --ion-box-shadow-color: #{$color-dark-blue-900};
  --ion-border-color: #{$color-dark-blue-200};
  --ion-backdrop-color: #{$color-dark-grey-900};
  --ion-backdrop-opacity: 0.2;
  
  /* steps generated based on $color-pure-white and $color-dark-blue-900
    https://ionicframework.com/docs/theming/themes
  */
  --ion-color-step-50: #f2f5f5;
	--ion-color-step-100: #e6eaec;
	--ion-color-step-150: #d9e0e2;
	--ion-color-step-200: #ccd5d9;
	--ion-color-step-250: #bfcbcf;
	--ion-color-step-300: #b3c0c6;
	--ion-color-step-350: #a6b6bc;
	--ion-color-step-400: #99abb3;
	--ion-color-step-450: #8ca1a9;
	--ion-color-step-500: #8096a0;
	--ion-color-step-550: #738c96;
	--ion-color-step-600: #66818c;
	--ion-color-step-650: #597783;
	--ion-color-step-700: #4d6c79;
	--ion-color-step-750: #406270;
	--ion-color-step-800: #335766;
	--ion-color-step-850: #264d5d;
	--ion-color-step-900: #194253;
	--ion-color-step-950: #0d384a;

  --ion-font-family: 'Montserrat';
}
