@import '../colors.scss';

ion-modal.modal--default {
  --height: 400px;
  --min-width: 700px;

  ion-header {  
    .top-row {
      display: flex;
      justify-content: space-between;
      padding: 40px 143px 26px 24px;
      border-bottom: 1px solid $color-dark-blue-200;
    
      .start {
        display: flex;
        align-items: center;
      }
    
      .end {
        display: flex;
        align-items: center;
      }
    } 
  }


  ion-footer {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 30px;
    border-top: 1px solid $color-dark-blue-200;
  }
}

ion-modal.modal--upload {
  // --height: auto;
  --min-height: 656px;
  --min-width: 813px;

  ion-footer {
    display: unset;
    border: unset;
  }
}