@font-face {   
  font-family: 'Montserrat';  
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {   
  font-family: 'Montserrat';  
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {   
  font-family: 'Montserrat';  
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {   
  font-family: 'Montserrat';  
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}

$font-size--xx-small: 0.625rem; /* 10px */
$font-size--x-small: 0.75rem; /* 12px */
$font-size--small: 0.875rem; /* 14px */
$font-size--default: 1rem; /* 16px */
$font-size--large: 1.125rem; /* 18px */
$font-size--x-large: 1.5rem; /* 24px */
$font-size--xx-large: 1.75rem; /* 28px */

$font-weight--regular: 400;
$font-weight--medium: 500;
$font-weight--semibold: 600;
$font-weight--bold: 700;

$line-height--paragraph: calc(1.5em + .2vw);

@mixin headline-h1() {
  font-size: $font-size--xx-large;
  font-weight: $font-weight--bold;
  line-height: 40px;
}

@mixin headline-h2() {
  font-size: $font-size--x-large;
  font-weight: $font-weight--bold;
  line-height: 29px;
}

@mixin headline-h3() {
  font-size: $font-size--large;
  font-weight: $font-weight--bold;
  line-height: 22px;
}

@mixin body-text-16($font-weight: $font-weight--regular) {
  font-size: $font-size--default;
  font-weight: $font-weight;
  line-height: 20px;
}

@mixin body-text-14($font-weight: $font-weight--regular) {
  font-size: $font-size--small;
  font-weight: $font-weight;
  line-height: 12px;
}

@mixin body-text-12($font-weight: $font-weight--regular) {
  font-size: $font-size--x-small;
  font-weight: $font-weight;
  line-height: 15px;
}

@mixin body-text-10($font-weight: $font-weight--regular) {
  font-size: $font-size--xx-small;
  font-weight: $font-weight;
  line-height: 12px;
}
