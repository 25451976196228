$border-radius--xx-large: 20px;
$border-radius--x-large: 16px;
$border-radius--large: 12px;
$border-radius--small: 8px;
$input-default-height: 50px;
$element-default-margin: 2px;

// menu
$menu-width--closed: 80px;

// ionic breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
// custom size
$xxl: 1300px;

@mixin breakpoint($viewport) {
  @if type-of($viewport) == number {
    @media screen and ( max-width: $viewport ) {
      @content;
    }
  }
  @else {
    @media screen and ( $viewport ) {
      @content;
    }
  }
}

.main-container {
  padding: 40px 40px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-main {
  display: flex;
  justify-content: center;
}

.flex-center-cross {
  display: flex;
  align-items: center;
}


