@import '../colors.scss';
@import '../utils.scss';
@import '../typography.scss';

$input-box-shadow: 12px 12px 25px rgba(11, 54, 72, 0.03);

@mixin ion-item-for-input() {
  --inner-padding-end: 0;
  --padding-start: 0;
  --background: transparent;
  overflow: visible;
  --border-radius: 12px;

  ion-input.ion-invalid.ion-dirty {
    --background: #{$color-red-25};
  }

  ion-select.ion-invalid.ion-touched {
    background-color: $color-red-25;
    border: 1px solid var(--ion-color-danger);
  }

  ion-datetime-button::part(native).ion-invalid.ion-touched {
    background-color: $color-red-25;
  }

  &.white {
    ion-input,
    ion-select {
      --background: #{$color-pure-white};

      &.ion-invalid.ion-dirty {
        border: 1px solid var(--ion-color-danger);
      }
    }

    &.item-has-focus {
      ion-input {
        border-color: $color-dark-blue-200;
      }
    }
  }

  &.icon-start {
    ion-input {
      --padding-start: 15px !important;
    }
  }
  &.button-end {
    ion-input {
      --padding-end: 60px !important;
    }

    ion-button {
      position: absolute;
      bottom: 35px;
      right: 20px;
      z-index: 5;

      &::part(native) {
        padding: 0;
      }
    }
  }
}

@mixin input-field() {
  border-radius: $border-radius--large;
  border: 1px solid transparent;
  --padding-start: 30px !important;
  --padding-end: 30px !important;
  --padding-top: 14px !important;
  --padding-bottom: 14px !important;
  margin-bottom: 26px;
  box-shadow: $input-box-shadow;

  ion-icon {
    color: $color-dark-grey-500;
    margin: 0 16px;
  }
}

@mixin input-label() {
  @include body-text-12;
  margin-top: 0;
  margin-bottom: 8px;
  padding-inline-start: 10px;
}

form.ng-submitted {
  ion-item {
    ion-input.ion-invalid,
    ion-select.ion-invalid {
      --background: #{$color-red-25};
      background-color: #{$color-red-25};
    }

    &.white {
      ion-input.ion-invalid,
      ion-select.ion-invalid {
        border: 1px solid var(--ion-color-danger);
      }
    }
  }

  ion-radio-group.ion-invalid {
    ion-label {
      color: var(--ion-color-danger);
    }
  }
}

ion-radio {
  width: 16px;
  height: 16px;
}

ion-checkbox {
  width: 16px;
  height: 16px;
}

ion-item.check-item {
  display: inline-block;
  --background: transparent;
  --border-radius: #{$border-radius--large};

  &::part(native) {
    padding-inline-start: 10px;
  }

  ion-label {
    margin-inline-end: 0;
  }

  ion-radio,
  ion-checkbox {
    flex: none;
  }
}

ion-segment.toggle {
  display: inline-flex;
  height: $input-default-height;
  // width: 216px;
  width: 187px;
  // padding: 2px 8px;
  padding: 2px 0px;
  border: 1px solid $color-dark-blue-200;
  border-radius: $border-radius--large;
  --background: #{$color-pure-white};

  ion-segment-button {
    &::part(indicator-background) {
      background-color: $color-light-blue-900;
    }

    &.ios::part(native) {
      @include body-text-16($font-weight--semibold);
      color: $color-pure-white;
    }

    &.ios::part(indicator-background) {
      border-radius: $border-radius--large;
    }

    &.segment-button-checked {
      ion-label {
        font-weight: $font-weight--semibold;
      }
    }

    &:not(.segment-button-checked) {
      ion-label {
        color: $color-dark-grey-900;
        font-weight: $font-weight--regular;
      }
    }
  }
}

ion-radio-group.radio-group--large {
  $radio-item-width: 180px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;

  &:not(:last-child) {
    margin-bottom: 22px;
  }

  ion-label.radio-group--large_label {
    margin-inline-end: 4px;
    text-align: end;
  }

  ion-item {
    &::part(native) {
      padding-inline-start: 20px;
      padding-inline-end: 10px;
    }

    &:last-child {
      margin-inline-end: 0;
    }

    width: $radio-item-width;
    min-width: $radio-item-width;

    --background: #{$color-dark-blue-25};
    --color: #{$color-dark-grey-700};
    --border-radius: #{$border-radius--large};

    &:hover {
      --background: #{$color-dark-blue-10};
      --color: rgba(#{$color-dark-grey-600-rgb}, 0.5);

      ion-radio:not(.radio-checked) {
        opacity: 0.35;
      }
    }

    &.active {
      --background: #{$color-light-blue-25};
      --color: #{$color-light-blue-900};

      ion-label {
        font-weight: $font-weight--bold;
      }
    }
  
    ion-label {
      @include body-text-16;
    }

    ion-radio {
      margin: 0;
    }
  }
}

@media (max-width: 1580px) {
  ion-radio-group.radio-group--large {
    ion-item {
      width: 150px;
      min-width: 150px;
    }
  }
}

@media (max-width: 1500px) {
  ion-radio-group.radio-group--large {
    ion-item {
      width: 120px;
      min-width: 120px;
    }
  }
}

.radio-group--default {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0 35px;

  padding-inline-start: 10px;
  margin-top: 18px;
  margin-bottom: 24px;

  &.full-width {
    flex-wrap: nowrap;
    margin-bottom: 30px;

    ion-label:first-child {
      flex-grow: 1;
    }
  }

  ion-label:first-child {
    align-self: center;
  }

  ion-radio-group {
    display: flex;
    align-items: center;
  }
}

.button-inline--radio-group {
  margin: 0;
  margin-top: -6px;
}

ion-item.input--default {
  @include ion-item-for-input;

  ion-label.label-stacked.sc-ion-label-ios-h {
    @include input-label;
  }

  ion-input,
  ion-select {
    @include input-field;

    --background: #{$color-dark-blue-25};
  }

  ion-select {
    border-color: $color-dark-blue-200;
    --background: transparent !important;
  }
  

  &.item-select {
    --color-hover: $color-dark-blue-200;
  }

  ion-datetime-button {
    position: absolute;
    bottom: 0;
    z-index: 5;
    width: 100%;

    &::part(native) {
      width: 100%;
      border-radius: $border-radius--large;
      border: 1px solid transparent;
      padding: 14px 30px;
      margin-bottom: 26px;
      background: $color-dark-blue-25;
      text-align: start;
      color: transparent;
      background-color: transparent;
    }
  }
}

ion-item.date-time {
  input {
    width: 100%;
    border-radius: $border-radius--large;
    border: 1px solid transparent;
    padding: 14px 30px;
    margin-bottom: 26px;
    background: $color-dark-blue-25;
    box-shadow: $input-box-shadow;
    
  }

  input::placeholder {
    color: $color-dark-grey-500;
  }
}

div.date-range {
  cursor: pointer;

  label.date-range-label {
    display: block;
    @include input-label;
  }

  mat-date-range-input {
    border-radius: $border-radius--large;
    border: 1px solid transparent;
    padding-inline-start: 30px;
    padding-inline-end: 30px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin-bottom: 26px;
    box-shadow: $input-box-shadow;
    background: $color-dark-blue-25;

    input::placeholder {
      color: $color-dark-grey-500;
    }
  }
}

// classes for using with form items
.col {
  display: grid;
  grid-column-gap: 12px;
  
  & > * {
    align-self: center;
  }

  &-66 {
    grid-template-columns: 2fr 1fr;
  }

  &-50 {
    grid-template-columns: 1fr 1fr;
  }

  &-33 {
    grid-template-columns: 1fr 1fr 1fr;

    @include breakpoint($xl) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &-33-66 {
    grid-template-columns: 1fr 2fr;
  }

  &-60-40 {
    grid-template-columns: 60% 40%;

    ion-item {
      ion-input,
      ion-select {
        margin: 0;
      }

      ion-select {
        width: 100%;
      }
    }
  }

  &-25 {
    grid-template-columns: repeat(4, 1fr);

    @include breakpoint($xl) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &-25-25-50 {
    grid-template-columns: 1fr 1fr 2fr;

    @include breakpoint($xl) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &-80 {
    grid-template-columns: 80%;

    @include breakpoint($xl) {
      grid-template-columns: 100%;
    }
  }

  &-85 {
    grid-template-columns: 85%;

    @include breakpoint($xl) {
      grid-template-columns: 100%;
    }
  }

  &-custom {
    grid-template-columns: auto 2fr;

    ion-item {
      ion-input,
      ion-select {
        margin: 0;
      }
    }
  }
}


.errorMsg {
  position: absolute;
  top: 80px;
  font-size: 11pt;
}