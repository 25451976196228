@import '../colors.scss';

.segment-heading {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid $color-dark-blue-300;
  margin-bottom: 32px;

  h3 {
    display: inline;
    margin: 0;
    padding-inline-start: 12px;
    color: $color-dark-blue-700;
    text-transform: capitalize;
  }

  ion-icon {
    width: 20px;
    height: 20px;
  }
}
