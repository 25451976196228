@import '../colors.scss';
@import '../typography.scss';
@import '../utils.scss';

ion-toast {
  --height: 76px;
  --border-radius: #{$border-radius--x-large};
  --background: var(--ion-color-secondary);
  --color: #{$color-pure-white};
  @include body-text-16($font-weight--semibold);

  &::part(icon) {
    color: var(--ion-color-primary);
  }

  &.success::part(icon) {
    color: var(--ion-color-success);
  }

  &.error::part(icon) {
    color: var(--ion-color-danger);
  }
}
