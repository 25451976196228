@import '../colors.scss';

.ngx-datatable {
    margin: 30px;
    box-shadow: 6px 12px 25px rgba(11, 54, 72, 0.08) !important;
    border-radius: 16px;
    
    .datatable-header {
        background-color: var(--ion-color-light-shade);
        height: 84px !important;
        padding-top: 30px;
        width: 100% !important;
        margin: 0;

        .datatable-header-cell {
            
            .datatable-header-cell-label {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                color: var(--ion-color-step-700);
            }
        }

        .datatable-header-cell:first-child {
            margin-inline-start: 50px;
        }
        .datatable-header-cell:last-child {
            margin-inline-end: 50px;
        }
    }

    .datatable-body {
        .datatable-body-row {
            .hiddenBtn {
                visibility: hidden;
            }
            .hiddenBtnStart {
              display: none;
            }
            .datatable-body-cell {
                border-bottom: 1px solid var(--ion-color-step-200);
                padding: 1.4rem 0rem;
                white-space: normal !important;

                .datatable-body-cell-label {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;

                    color: #{$color-dark-grey-700};
                }

                .chipItem {
                    margin-top: -12px;
                    --padding-start: 0px;
                    ion-label {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #{$color-dark-grey-700};
                    }
                }
            }

            .datatable-body-cell:first-child {
                margin-inline-start: 50px;
            }
            .datatable-body-cell:last-child {
                margin-inline-end: 50px;
            }
        }
    }

    .datatable-footer {
        padding-inline-start: 50px;
        padding-inline-end: 50px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */


        /* Dark Blue 700 */

        color: var(--ion-color-step-800);

        .datatable-pager {
            margin: 0 10px;
      
            li {
              vertical-align: middle;
      
              &.disabled a {
                color: var(--ion-color-step-800) !important;
                background-color: transparent !important;
              }
      
              &.active a {
                color: $color-pure-white;
                background-color: $color-dark-blue-900;
                font-weight: bold;
              }
            }
      
            a {
              height: 22px;
              min-width: 24px;
              line-height: 22px;
              padding: 0 6px;
              border-radius: 3px;
              margin: 6px 3px;
              text-align: center;
              vertical-align: top;
              color: $color-dark-blue-900;
              text-decoration: none;
              vertical-align: bottom;
              border-radius: 8px;
      
              &:hover {
                color: $color-pure-white;;
                background-color: $color-dark-blue-900;
              }
            }
      
            .datatable-icon-left,
            .datatable-icon-skip,
            .datatable-icon-right,
            .datatable-icon-prev {
              font-size: 20px;
              line-height: 20px;
              padding: 0 3px;
            }
          }
    }

    &:not(.cell-selection) {
        .datatable-body-row {
          &:hover,
          &:hover .datatable-row-group {
            background-color: $color-dark-blue-10 !important;
            transition-property: background !important;
            transition-duration: 0.3s !important;
            transition-timing-function: linear !important;

            .hiddenBtn {
                visibility: visible;
            }
            .hiddenBtnStart {
              display: block;
            }

            .text-next-to-buttons-table {
              display: none;
              visibility: hidden;
            }
          }
        }
      }
}

@media (max-height: 739px) {
  .ngx-datatable {
    &.docuSignTable {
        .datatable-body {
          height: 28vh !important;
          overflow-x: hidden !important;
        }
    }
  }
  .statusWrap {
    top: 40% !important;
  }
  // .footer {
  //   position: absolute;
  //   right: 0;
  //   bottom: 0;
  //   width: 534px;
  //   background: transparent !important;
  //   box-shadow: none !important;
  //   border: none !important;
  // }

  @media (max-width: 1570px) {
    .footer {
      // width: 277px;
      .button-utlined, .button-default {
        min-width: 100px !important;
      }
    }
  } 

  @media (max-width: 1250px) {
    .footer {
      width: 277px;
      .button-utlined, .button-default {
        min-width: 200px !important;
      }
    }
  }
}

@media (min-height: 740px) {
  .ngx-datatable {
    &.docuSignTable {
        .datatable-body {
          height: 20vh !important;
          overflow-x: hidden !important;
        }
    }
  }
}

@media (min-height: 850px) {
  .ngx-datatable {
    &.docuSignTable {
        .datatable-body {
          height: 26vh !important;
          overflow-x: hidden !important;
        }
    }
  }
}

@media (min-height: 940px) {
  .ngx-datatable {
    &.docuSignTable {
        .datatable-body {
          height: 31vh !important;
          overflow-x: hidden !important;
        }
    }
  }
}

@media (min-height: 1000px) {
  .ngx-datatable {
    &.docuSignTable {
        .datatable-body {
          height: 36vh !important;
          overflow-x: hidden !important;
        }
    }
  }
}

@media (min-height: 1070px) {
  .ngx-datatable {
    &.docuSignTable {
        .datatable-body {
          height: 41vh !important;
          overflow-x: hidden !important;
        }
    }
  }
}

@media (min-height: 1170px) {
  .ngx-datatable {
    &.docuSignTable {
        .datatable-body {
          height: 45vh !important;
          overflow-x: hidden !important;
        }
    }
  }
}

@media (min-height: 1230px) {
  .ngx-datatable {
    &.docuSignTable {
        .datatable-body {
          height: 48vh !important;
          overflow-x: hidden !important;
        }
    }
  }
}

// @media (min-height: 1140px) {
//   .ngx-datatable {
//     &.docuSignTable {
//         .datatable-body {
//           height: 42vh !important;
//         }
//     }
//   }
// }

// @media (min-height: 1144px) {
//   .ngx-datatable {
//     &.docuSignTable {
//         .datatable-body {
//           height: 43vh !important;
//         }
//     }
//   }
// }

// @media (min-height: 1160px) {
//   .ngx-datatable {
//     &.docuSignTable {
//         .datatable-body {
//           height: 45vh !important;
//         }
//     }
//   }
// }

// @media (min-height: 1200px) {
//   .ngx-datatable {
//     &.docuSignTable {
//         .datatable-body {
//           height: 48vh !important;
//         }
//     }
//   }
// }