@import '../colors.scss';
@import '../typography.scss';
@import '../utils.scss';

$button-default-width: 200px;
$button-narrow-width: 150px;

@mixin default-button-style() {
  height: $input-default-height;
  min-width: $button-default-width;
  --border-radius: #{$border-radius--large};
  --padding-top: 15px;
  --padding-bottom: 15px;
  --background-activated-opacity: 1;
  @include body-text-16($font-weight--bold);
}

ion-button {
  &.button-default {
    @include default-button-style;
    --background: #{$color-light-blue-900};
    --background-hover: #{$color-light-blue-500};
    --background-activated: #{$color-light-blue-600};
    --background-focused: #{$color-light-blue-600};
  
    --color: #{$color-pure-white};
    --box-shadow: 8px 6px 15px rgba(0, 116, 148, 0.04), 6px 8px 25px rgba(1, 76, 96, 0.09);
  }

  &.button-outlined {
    @include default-button-style;
    --background: transparent;
    --background-hover: #{$color-light-blue-25};
    --background-activated: #{$color-light-blue-100};
    --background-focused: #{$color-light-blue-100};

    --color: #{$color-dark-grey-700};

    --border-color: #{$color-dark-grey-300};
    --border-style: solid;
    --border-width: 1px;

    filter: drop-shadow(6px 8px 25px rgba(11, 54, 72, 0.01));
  }

  &.button-icon {
    height: $input-default-height;
    ion-icon {
      margin: 0px;
    }
  }

  &.narrow {
    min-width: $button-narrow-width;
  }
}

ion-back-button {
  display: inline-block;

  &::part(icon) {
    height: 16px;
    width: 16px;
    margin-inline-end: 30px;
  }

  &::part(text) {
    @include headline-h2;
  }
}

ion-button.fake-back-button {
  display: inline-block;
  @include headline-h2;

  ion-icon {
    height: 16px;
    width: 16px;
    margin-inline-end: 30px;
  }
}