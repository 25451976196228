// light blue
$color-light-blue-950: #007494;
$color-light-blue-900: #009AC5; // primary
$color-light-blue-900-rgb: 0, 154, 197;
$color-light-blue-700: #33AED1; 
$color-light-blue-600: #4DB8D6; 
$color-light-blue-500: #80CCE2; 
$color-light-blue-300: #A6DCEB; 
$color-light-blue-100: #CCEBF3; 
$color-light-blue-50: #E5F5F9; 
$color-light-blue-25: #F0F9FC; 

// dark blue
$color-dark-blue-900: #002D40; // secondary
$color-dark-blue-900-rgb: 0, 45, 64;
$color-dark-blue-700: #335766;
$color-dark-blue-600: #4D6C79;
$color-dark-blue-500: #80969F;
$color-dark-blue-400: #99ABB3;
$color-dark-blue-300: #B3C0C6;
$color-dark-blue-300: #B3C0C6;
$color-dark-blue-200: #CCD5D9;
$color-dark-blue-100: #E0E6E8;
$color-dark-blue-50: #E5EAEC;
$color-dark-blue-25: #F0F2F4; // medium
$color-dark-blue-25-rgb: 240, 242, 244; // medium
$color-dark-blue-10: #F7F9F9;
$color-dark-blue-5: #FAFBFC; // light
$color-dark-blue-5-rgb: 250, 251, 252; // light

// dark grey
$color-dark-grey-900: #313131;
$color-dark-grey-700: #525252;
$color-dark-grey-600: #686868;
$color-dark-grey-600-rgb: 104, 104, 104;
$color-dark-grey-650: #7D7D7D;
$color-dark-grey-500: #939393;
$color-dark-grey-350: #B3B3B3;
$color-dark-grey-300: #BEBEBE;
$color-dark-grey-200: #E9E9E9;

// light grey
$color-light-grey-900: #F8F8F8;
$color-light-grey-700: #FAFAFA;

$color-pure-white: #FFFFFF;
$color-pure-white-rgb: 255, 255, 255;

// red
$color-red-950: #d3343f; // added manualy
$color-red-900: #FE414D; // danger
$color-red-900-rgb: 254, 65, 77; // danger
$color-red-600: #FE7A82;
$color-red-500: #FFA0A6; // tertiary
$color-red-500-rgb: 255, 160, 166;
$color-red-300: #FFC6CA;
$color-red-50: #FFECED;
$color-red-25: #FFF4F4;

// green
$color-green-1000: #234D42;
$color-green-950: #337060;
$color-green-900: #00BBA5; // success
$color-green-900-rgb: 0, 187, 165; // success
$color-green-300: #CCF1ED;
$color-green-10: #E5F8F6;

// yellow
$color-yellow-1000: #A56D09;
$color-yellow-950: #D58D0C; // warning
$color-yellow-950-rgb: 213, 141, 12;
$color-yellow-900: #F3A821;

// cyan
$color-cyan-950: #34BBF4;
$color-cyan-900: #94DBF9;
$color-cyan-300: #DFF4FD;

// cobalt blue
$color-cobalt-blue-900: #0367A5;
$color-cobalt-blue-700: #3585B7;
$color-cobalt-blue-500: #81B3D2;
$color-cobalt-blue-300: #B3D1E4;
$color-cobalt-blue-50: #E6F0F6;

// dark
$color-midnight-900: #001219;
$color-midnight-900-rgb: 0, 18, 25;
$color-navy-900: #2F3044;
$color-navy-700: #3C3B4D;
