@import '../typography.scss';
@import '../colors.scss';

.status {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 32px;
  margin: $element-default-margin;
  border-radius: $border-radius--small;
  @include body-text-12($font-weight--bold);
  text-transform: capitalize;

  &.prospect,
  &.Prospect {
    background-color: $color-light-blue-50;
    color: $color-light-blue-900;
  }

  &.active,
  &.Active {
    background-color: $color-green-10;
    color: $color-green-900;
  }

  &.inactive,
  &.Inactive {
    background-color: $color-dark-blue-50;
    color: $color-dark-blue-900;
  }

  &.deceased,
  &.Deceased {
    background-color: $color-red-50;
    color: $color-red-900;
  }
}
