@import '../typography.scss';

.segment-menu {
  justify-content: flex-start;
  gap: 100px;
  padding-inline-start: 50px;
  padding-inline-end: 50px;

  ion-segment-button {
    max-width: fit-content;
    min-width: min-content;
    --padding-start: 0;
    --padding-end: 0;
    @include body-text-14($font-weight--bold);
    text-transform: capitalize;
    letter-spacing: normal;
    --indicator-height: 4px;
    ion-label {
      margin-bottom: 27px;
      margin-top: 27px;
    }
  }
}
